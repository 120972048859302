import { createRouter, createWebHistory } from "vue-router";
import { registerGuard } from './Guard';
import PageNotFound from "../modules/Errors/PageNotFound.vue";
import Main from "../modules/Main.vue";

const routes = [
    {
        path: "/",
        name: "landing_page",
        component: Main,
        meta: {
            title: "Home",
        }
    },
    {
        path: "/:catchAll(.*)",
        component: PageNotFound,
        meta: {
            title: "Siden kunne ikke findes",
            showMenuButton: true,
        }
    }
]

const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  routes: routes,
});

registerGuard(router);

export default router;