import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    murderGames: "Murder Games",
    pageNotFound: "Page not found",
    goToHomePage: "Go to home page",
    notLoggedIn: "You can login to save your quizzes",
    welcomeToMurderGames: "Welcome to Murder Games, the game where you must kill your friends!",
    searchForSongOnSpotify: "Search for a song on Spotify",
    ok: 'Ok',
    cancel: 'Cancel',
    search: 'Search',
    searchForSong: 'Search for a song',
    addSong: '+ song',
    addNewSong: 'Add new song',
    addCategory: '+ category',
    login: 'Log in',
    logout: 'Log out',
    signUp: 'Sign up',
    signUpForMusicQuiz: 'Sign up for Music Quiz',
    create: 'Create',
    createGame: 'Create Game',
    joinGame: 'Create Game',
    gameId: 'Game ID',
    emailAddress: 'Email address',
    password: 'Password',
    mobile: 'Mobile phone number',
    wellNeverShareYourEmail: "We'll never share your email with anyone else!",
    pleaseEnterValidEmailAddress: 'Please enter a valid email address',
    emailAlreadyExists: 'Email already exists',
    spotifyNotConnected: "Spotify not connected, you need to connect to spotify to add songs",
    connectToSpotify: 'Connect to Spotify',
    home: 'Home',
    firstName: 'First name',
    lastName: 'Last name',
    gameCode: 'Enter game code',
  },
  da: {
    musicQuiz: "Musik Quiz",
  },
};

const i18n = new createI18n({
  locale: "en",
  legacy: false,
  messages: messages,
});

export default i18n;