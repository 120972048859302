<template>
   404 - {{ $t('pageNotFound') }}.<br />
    <router-link to="/">{{ $t('goToHomePage') }}</router-link>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
    });
</script>

<style>
</style>
