<template>
    <div class="row my-3 fw-bold" v-if="!joined">
      <div class="col text-center">
          {{ $t('welcomeToMurderGames')}}
      </div>
    </div>
    <div class="row" v-if="!authenticated">
        <div class="col text-center">
            You need to login to play the game!
        </div>
    </div>
    <div class="row" v-if="authenticated && !joined">
        <div class="col">
            <div class="row">
                <div class="col text-center">
                    <button type="button" class="btn btn-danger" @click="createGame">Create new game</button>
                </div>
            </div>
            <div class="row mt-5 justify-content-center">
                <div class="col-12 col-md-5 text-center">
                    <h3>Join existing game</h3>
                    <div class="my-3">
                        <label for="joinGameCode" class="form-label">{{$t('gameCode')}}</label>
                        <input v-model="code" type="text" @input="code=code.toUpperCase()" class="form-control text-center" id="joinGameCode">
                        <span class="text-white" v-if="joinError != ''">{{ joinError }}</span>
                    </div>
                    <button type="button" class="btn btn-success" @click="joinGame">Join game</button>
                </div>
            </div>
        </div>
    </div>
    <GamePage v-if="joined"/>
</template>

<script lang="ts" setup>
    import { ref, onMounted, watch } from 'vue';
    import { storeToRefs } from 'pinia';
    import { directus } from '@/services/directus';
    import { useGameStore } from '@/stores/game'
    import GamePage from '@/modules/GamePage.vue';

    const gameStore = useGameStore();
    const joinError = ref('');
    const { game, joined } = storeToRefs(useGameStore());
    const code = ref('');
    const authenticated = ref(false);

    onMounted(() => {
        directus.isAuthenticated().then((isAuthenticated: boolean) => {
            authenticated.value = isAuthenticated;
            if (isAuthenticated) {
                gameStore.getGame(null)
                .catch((error: string) => {
                    console.log('No games found');
                });
            }
        });
    });

    const joinGame = async () => {
        if (code.value == '') {
            joinError.value = 'Please enter a game code';
            return;
        }

        joinError.value = '';
        gameStore.joinGame(code.value)
        .catch((error: any) => {
            console.log('Join error', error);
            joinError.value = error;
        }); 
    }

    const createGame = async () => {
        await gameStore.createGame();
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>