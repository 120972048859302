<template>
    <div class="row mt-3" v-if="game?.code">
        <div class="col text-success">
            <h2>
                <span v-if="game?.code != null">{{ gameStatus }}</span>
            </h2>
        </div>
        <div class="col text-end">
            Game code:<br /><span class="text-white">{{ game.code }}</span>
        </div>
    </div>
    <div class="row mt-3" v-if="gameStatus == 'Started' && !isDead">
        <div class="col">
            <div class="row">
                <div class="col text-center">
                    <h3>Your target</h3>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <span class="text-white fw-bolder">{{ killTarget }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" v-if="gameStatus == 'Started' && isDead">
        <div class="col text-center">
            <h3>You are so dead! :(</h3>
            <span class="text-white fw-bolder">Better luck next time</span>
        </div>
    </div>
    <div class="row mt-5 text-center" v-if="gameStatus == 'Started' && !isDead">
        <div class="col">
            <button type="button" class="btn btn-danger btn-lg" @click="imDead()">Click here if you died :(</button>
        </div>
    </div>
    <div class="row mt-5 text-center"  v-if="gameStatus == 'Started' && !isDead && 1 == 2">
        <div class="col">
            <button type="button" class="btn btn-warning btn-lg">Click here you saw a murder :O</button>
        </div>
    </div>
    <div class="row mt-5 text-center"  v-if="gameStatus == 'Finished'">
        <div class="col text-warning">
            <h4>Winner: {{ winner }}</h4>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <h3>Players</h3>
        </div>
    </div>
    <div class="row text-white">
        <div class="col">
            <div class="row"  v-for="player in game?.players" :key="player.id">
                <div class="col-2">
                    <span v-if="showStatus">{{ player.alive == "1" ? 'Alive' : 'Dead'}}</span>
                </div>
                <div class="col-auto">
                    {{ player.directus_users_id.first_name }} {{ player.directus_users_id.last_name }}
                </div>
            </div>
        </div>
    </div>
    <div class="row my-4" v-if="myGame && gameStatus == 'Not started'">
        <div class="col text-center">
            <button type="button" class="btn btn-danger" @click="startGame" :disabled="(game.players.length < minimumPlayers) ? true : false">Start game</button>
            <div class="mt-2 text-success" v-if="(game.players.length <  minimumPlayers)">Not enough players, {{ minimumPlayers }} is required!</div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col">
            Created by: {{ game?.user_created?.first_name }} {{ game?.user_created?.last_name }}
        </div>
    </div>
    <div class="row" v-if="game?.date_created">
        <div class="col" >
            Created at: {{ new Date(game?.date_created).toLocaleDateString('da-dk', { weekday:"short", year:"numeric", month:"short", day:"numeric", hour: "numeric", minute:"numeric"}) }}
        </div>
    </div>
    <div class="row mt-5">
        <div class="col text-end">
            <button type="button" class="btn btn-info btn-xm" @click="leaveGame">Leave game</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import { storeToRefs } from 'pinia';
    import { directus } from '@/services/directus';
    import { useGameStore } from '@/stores/game'

    const gameStore = useGameStore();
    const { game } = storeToRefs(useGameStore());

    const minimumPlayers = ref<number>(3);

    const myGame = computed(() => {
        if (game.value?.user_created?.id == directus.user?.id) {
            return true;
        }

        return false;
    });

    const killTarget = computed(() => {
        const me = game.value?.players?.filter((player) => {
            return player.directus_users_id.id == directus.id;
        })[0] ?? '';

        if (me == '') {
            return 'Unknown target';
        }

        return me.current_target.first_name + ' ' + me.current_target.last_name;
    });

    const isDead = computed(() => {
        const me = game.value?.players?.filter((player) => {
            return player.directus_users_id.id == directus.id;
        })[0] ?? '';

        if (me == '') {
            return true;
        }

        return me.alive == '1' ? false: true;
    });

    const winner = computed(() => {
        const winner = game.value?.players?.filter((player) => {
            return player.alive == '1';
        })[0] ?? '';

        if (winner == '') {
            return 'Unknown';
        }

        return winner.directus_users_id.first_name + ' ' + winner.directus_users_id.last_name;
    });

    const showStatus = computed(() => {
        if (isDead.value == true) {
            return true;
        }
        if (game.value?.status == 'published') {
            return false;
        }

        return true;
    });

    const gameStatus = computed(() => {
        if (game.value?.status == 'draft') {
            return 'Not started';
        } else if (game.value?.status == 'published') {
            return 'Started';
        }

        return 'Finished';
    });

    const leaveGame = async () => {
        gameStore.leaveGame()
        .catch((error: any) => {
            console.log(error);
        });
    }

    const startGame = async () => {
        gameStore.startGame()
        .catch((error: any) => {
            console.log(error);
        });
    }

    const imDead = async () => {
        gameStore.imDead()
        .catch((error: any) => {
            console.log(error);
        });
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>