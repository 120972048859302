<template>
    <div class="modal fade show" id="confirmDialog" tabindex="-1" aria-labelledby="confirmDialogTitle" aria-modal="true" role="dialog" style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content h-100">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('login')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="emit('cancel')"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="loginEmailInput" class="form-label">{{$t('emailAddress')}}</label>
                                <input v-model="email" type="email" class="form-control" id="loginEmailInput" aria-describedby="emailHelp">
                            </div>
                            <div class="mb-3">
                                <label for="loginPasswordInput" class="form-label">{{$t('password')}}</label>
                                <input v-model="password" type="password" class="form-control" id="loginPasswordInput">
                            </div>
                        </div>
                        <div class="row" v-if="error.length > 0">
                            <div class="col">
                                <div class="alert alert-danger" role="alert">
                                    {{error}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="emit('cancel')">{{$t('cancel')}}</button>
                    <button type="button" class="btn btn-primary" @click="okPressed" :disabled="okDisabled">{{$t('login')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import { directus } from '@/services/directus';
    const emit = defineEmits(['ok', 'cancel'])

    const email = ref('');
    const password = ref('');
    const error = ref('');

    const okPressed = () =>  {
        directus.login(email.value, password.value)
        .then(() => {
            emit('ok', {});
        })
        .catch((errorMsg: any) => {
            console.log(error);
            error.value = "An error occured. check email and password and try again."
        });
    }

    const okDisabled = computed(() =>  {
        if (email.value.length == 0 && password.value.length == 0)
        {
            return true;
        }
        if (!validEmail) {
            return true;
        }

        return false;
    });

    const validEmail = () =>  {
        if (email.value.length == 0) {
            return true;
        }

        var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return re.test(email.value);
    }

</script>

<style scoped> 
    .modal-content {
        max-height: 400px !important;
    }

    .artists:not(:last-child):after {
        content: ', '
    }

    .selectedRow {
        background-color: #e9ecef;
    }

    .pointer {
        cursor: pointer;
    }
</style>