import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/scss/app.scss';
import axios from 'axios'
import router from './routes/router';
import VueAxios from 'vue-axios'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import i18n from "./i18n/index";
import CountryFlag from 'vue-country-flag-next'
import { createHead } from '@unhead/vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'


const clickOutside = {
    beforeMount: (el: any, binding: any) => {
      el.clickOutsideEvent = (event: any) => {
        if (!(el == event.target || el.contains(event.target))) {
          if (typeof(binding.value) == 'object') {
            binding.value.handler(binding.value.value);
          }
          else {
            binding.value();
          }
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: (el: any) => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };

const pinia = createPinia()
const app = createApp(App);
const head = createHead()

app.use(router);
app.use(pinia)
app.use(i18n);
app.use(VueAxios, axios)
app.use(BootstrapIconsPlugin)
app.component('country-flag', CountryFlag)
app.directive("click-outside", clickOutside)
app.use(head)
app.use(VueReCaptcha, {
  siteKey: '6LdM2xkpAAAAADBvRDzPwLjWfm0t_2LJC7zdlR30',
  loaderOptions: {
    useRecaptchaNet: false,
    useEnterprise: false,
    autoHideBadge: true,
    renderParameters: {},
    explicitRenderParameters: {},
  }
})
router.isReady().then(() => {
  app.mount('#app');
});