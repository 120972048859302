import { createDirectus, rest, authentication, AuthenticationConfig, refresh, readMe, createUser } from '@directus/sdk'
import DirectusWebSocket from './directusWebSocket';
import { Schema } from '@/types/Schema.type';
import { CustomUserFields } from '@/types/DirectusUsers.type';

class DirectusHandler {

    private _config: AuthenticationConfig;
    private _loginStatus: boolean;
    private _accessToken: string;
    private _directus;
    private _websocket: DirectusWebSocket;
    private _authing: boolean;
    private _refreshTimer: any;

    private ready: boolean;
    private _user: CustomUserFields;

    constructor() {
        this._authing = false;
        this._config = {
            autoRefresh: true,
            msRefreshBeforeExpires: 30000,
            credentials: 'include'
        }

        this._accessToken = '';
        this._loginStatus = false;
        this._directus = createDirectus<Schema>(process.env.VUE_APP_API_URL ?? '')
                            .with(authentication('cookie', this._config))
                            .with(rest({ credentials: 'include' }));

        this._websocket = new DirectusWebSocket();
        this._user = {
            email: '',
            first_name: '',
            last_name: '',
            mobile: '',
            password: '',
            role: '',
            status: '',
        };
    }

    async init(): Promise<void> {
        try {
            await this.isAuthenticated();
        } catch(error) {
            this.ready = true;
            console.log('Not logged in');
        }
    }

    get user(): CustomUserFields {
        return this._user;
    }
    
    get id(): string {
        return this._user.id ?? '';
    }

    get role() {
        return this._user.role;
    }

    get loginStatus(): boolean {
        return this._loginStatus;
    }

    get name() {
        return this._user.first_name + ' ' + this._user.last_name;
    }

    get email() {
        return this._user.email;
    }

    get api() {
        return this._directus;
    }

    get websocket(): DirectusWebSocket {
        return this._websocket;
    }

    get accessToken(): string {
        return this._accessToken;
    }

    async isReady(): Promise<boolean> {
        return new Promise ((resolve, reject) => {
            if (this.ready) {
                resolve(true);
            }
            const readyInterval = setInterval(() => {
                if (this.ready) {
                    clearInterval(readyInterval);
                    resolve(true);
                }
            }, 100);
        });
    }

    async signup(email: string, password: string, mobile: string, firstName: string, lastName: string): Promise<void> {
        return new Promise ((resolve, reject) => {
            
            this._directus.request(createUser({
                email: email,
                password: password,
                status: 'active',
                first_name: firstName,
                last_name: lastName,
                role: process.env.VUE_APP_USER_ROLE,
                mobile: mobile,
            }))
            .then(() => {
                this.login(email, password).then(() => {
                    resolve();
                })
                .catch((error) => {
                    console.log('sign in error');
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
    
        });
    }

    async login(email: string, password: string): Promise<void> {
        return new Promise ((resolve, reject) => {
                this._directus.login(
                    email,
                    password
                )
                .then((response: any) => {
                this.isAuthenticated()
                this._getUserDetails()
                .then(() => {
                    resolve();
                })
                .catch((error: any) => {
                    reject(error);
                });
            })
            .catch((error: any) => {
                console.error(error);
                reject(error);
            });
        });
    }

    async logout(): Promise<void> {
        return new Promise ((resolve, reject) => {
            this._directus.logout().then(() => {
                this._loginStatus = false;
                this._user.email = '';
                this._user.first_name = '';
                this._user.last_name = '';
                this._user.mobile = '';
                this._user.password = '';
                this._user.role = '';
                this._user.status = '';
                localStorage.removeItem('currentGame');
                resolve();
            });
        });
    }

    public async isAuthenticated(): Promise<boolean> {
        if (this._authing == true) {
            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (this._authing == false) {
                        clearInterval(interval);
                        resolve(this._loginStatus);
                    }
                }, 50);
            });
        }
        if (this._loginStatus == true) {
            return true;
        }

        this._authing = true;
        return new Promise((resolve) => {
            this.refresh()
                .then((response: any) => {
                    this._loginStatus = true;
                    this._authing = false;
                    this._getUserDetails()
                    .then(() => {
                        resolve(true);
                    });
                })
                .catch((err: any) => { 
                    this._loginStatus = false;
                    this._authing = false;
                    resolve(false);
                });
        });
    }

    public async authenticateWebSocket(): Promise<void> {
        this._websocket.connect();
        this._websocket.authenticate(this._accessToken);
    }

    private async refresh(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._directus
            .request(refresh('cookie'))
            .then((response: any) => {
                if (this._refreshTimer) {
                    clearTimeout(this._refreshTimer);
                }
                this._refreshTimer = setTimeout(() => {
                    console.log('Auto refreshing token');
                    this.refresh();
                }, response.expires - 1000*60);
                
                this._directus.setToken(response.access_token);
                this._accessToken = response.access_token;
                this.authenticateWebSocket();
                resolve(true);
            })
            .catch((err: any) => {
                reject(err);  
            });
        });
    }

    private async _getUserDetails(): Promise<void> {
        return new Promise ((resolve, reject) => {
            this._directus.request(readMe())
            .then((response: any) => {
                this._user.email = response.email ?? '';
                this._user.id = response.id ?? '';
                this._user.mobile = response.mobile ?? '';
                this._user.role = response.role ?? '';
                this._user.first_name = response.first_name;
                this._user.last_name = response.email;

                this._loginStatus = true;
                resolve();
            })
            .catch((error: any) => {
                console.error("ddddd", error);
                this._loginStatus = false;
                reject(error);
            })
            .finally(() => {
                this.ready = true;
            });
        });
    }

}

export const directus = new DirectusHandler();