<template>
  <Signup @ok="closeModals" @cancel="closeModals" v-if="showSignupDialog"/>
  <Login @ok="closeModals" @cancel="closeModals" v-if="showLoginDialog"/>

  <div class="container mt-1">
    <div class="row">
      <div class="col-auto">
      </div>
      <div class="col text-end">
        <button v-if="!loginStatus" class="btn btn-sm btn-outline-secondary" type="button" @click="doShowSignupDialog()">{{ $t('signUp') }}</button>
        <button v-if="!loginStatus" class="btn btn-sm btn-outline-secondary mx-2" type="button" @click="doShowLoginDialog()">{{ $t('login') }}</button>
        <button v-if="loginStatus" class="btn btn-sm btn-outline-secondary mx-2" type="button" @click="logout()">{{ $t('logout') }}</button>
      </div>
    </div>
    <div class="row">
      <div class="col mt-3 text-center">
        <h1>{{$t('murderGames')}}</h1>
      </div>
      <div class="row" v-if="!loginStatus">
        <div class="col-12">
          <div class="text-center">
            {{$t('notLoggedIn')}}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
    import { ref, onMounted, inject } from 'vue';
    import Signup from "@/components/Signup.vue";
    import Login from "@/components/Login.vue";
    import { useRouter } from 'vue-router';
    import { directus } from '@/services/directus';
    
    const showSignupDialog = ref(false);
    const showLoginDialog = ref(false);
    const isEditor = ref(false);
    const loginStatus = ref(false);
    const loginName = ref('');
    const $router = useRouter();
    const ready = ref(false);

    onMounted(() => {
      directus.init();
      directus.isReady()
      .then(() => {
        loginStatus.value = directus.loginStatus;
        loginName.value = directus.name;
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        ready.value = true;
      });

    });

    const doShowSignupDialog = () => {
        showSignupDialog.value = true;
    }
    const doShowLoginDialog = () => {
        showLoginDialog.value = true;
    }
    const closeModals = () => {
        showSignupDialog.value = false;
        showLoginDialog.value = false;

        directus.isReady().then(() => {
          loginStatus.value = directus.loginStatus;
          loginName.value = directus.name;
        });
    }
    const logout = () => {
        directus.logout().then(() => {
            loginStatus.value = false;
        })
    }

</script>

<style>
</style>
