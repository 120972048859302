import { directus } from "./directus";
import { SubscriptionOptions } from "@/types/SubscriptionOptions.type";

export default class DirectusWebSocket {

    private _socket: WebSocket|null;
    private _connected: boolean;
    private _authenticated: boolean|null;
    private _handlers: any;
    private _subscriptions: any;

    constructor() {
        this._handlers = {};
        this._subscriptions = {};
    }

    public connect(): void {
        console.log('Connect');
        if (this._connected) {
            return;
        }
        this._socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_BASE_URL);
        this._authenticated = null;
        this._connected = false;
        this._addListeners();
    }

    private async isConnected(): Promise<boolean> {
        while (this._connected == false) {
            await sleep(50);
        }
        return this._connected;
    }

    private async isAuthenticated(): Promise<boolean> {
        console.log('Is Authenticated');
        await this.isConnected();
        while (this._authenticated == null) {
            await sleep(50);
        }

        return this._authenticated;
    }

    public async authenticate(access_token: string): Promise<void> {
        await this.isConnected();
        this._socket?.send(JSON.stringify({ type: 'auth', access_token }));
    }

    public async subscribe(collection: string, component: string, options?: SubscriptionOptions): Promise<void> {
        const key = options?.uid ?? collection;
        await this.isAuthenticated();

        if (!this._subscriptions[key]) {
            this._subscriptions[key] = {};
        }
        this._subscriptions[key][component] = true;

        this._sendSubscription(collection, options?.uid ?? collection, options);

    }

    /*public async reSubscribe(): Promise<void> {
        await this.isAuthenticated();
        console.log('Resubscribing');
        for (const collection in this._subscriptions) {
            this._sendSubscription({ collection: collection, uid: collection });
        }
    }*/

    public createUID(collection: string, query?: object): string {
        return btoa(collection + '_' + JSON.stringify(query));
    }

    private _sendSubscription(collection: string, uid: string, options?: SubscriptionOptions): void {
        const subscription: any = { 
            type: 'subscribe',
            collection: collection,
            uid: uid
        };

        if (options?.query) {
            subscription['query'] = options.query;
        }

        if (options?.id) {
            subscription['id'] = options.id;
        }

        if (options?.ids) {
            subscription['ids'] = options.ids;
        }

        console.log('Sending subscription', subscription);

        this._socket?.send(
            JSON.stringify(subscription)
        );
    }

    public unsubscribe(collection: string, component: string): void {
        if (this._subscriptions[collection] && this._subscriptions[collection][component]) {
            delete this._subscriptions[collection][component];
        }
        if (!this._subscriptions[collection]) {
            this._socket?.send(JSON.stringify({ type: 'unsubscribe', collection: collection }));
        }

    }

    public addHandler(collection: string, component: string, method: string, callback: any): void {
        if (!this._handlers[collection]) {
            this._handlers[collection] = {};
        }
        if (!this._handlers[collection][component]) {
            this._handlers[collection][component] = {};
        }
        this._handlers[collection][component][method] = callback;
    }

    public removeHandlers(collection: string, component: string): void {
        delete this._handlers[collection][component];
    }

    public sendMessage(message: any): void {
        this._socket?.send(JSON.stringify(message));
    }

    private _addListeners(): void {
        this._socket?.removeEventListener('open', () =>  { this.connectionOpen() });
        this._socket?.removeEventListener('message', (message: any) => { this.handleMessage(message) });
        this._socket?.removeEventListener('close', () => { this.connectionClosed() });
        this._socket?.removeEventListener('error', (error: any) => { this.connectionError(error) });
        this._socket?.addEventListener('open', () =>  { this.connectionOpen() });
        this._socket?.addEventListener('message', (message: any) => { this.handleMessage(message) });
        this._socket?.addEventListener('close', () => { this.connectionClosed() });
        this._socket?.addEventListener('error', (error: any) => { this.connectionError(error) });
    }

    private connectionOpen(): void {
        this._connected = true;
        console.log({ event: 'onopen' });
    }

    private async connectionClosed(): Promise<void> {
        this._connected = false;
        this._authenticated = null;
        console.log({ event: 'onclose' });
        this._socket = null;
        await sleep(1000);
        console.log('Reconnecting');
        this.connect();
        const token = await directus.accessToken ?? '';
        this.authenticate(token);
        //this.reSubscribe();
    }

    private connectionError(error: any): void {
        console.log({ event: 'onerror', error });
    }

    private handleMessage(message: any): void {
        const data = JSON.parse(message.data);
        console.log({ event: 'onmessage', message });
        console.log(data);
        if (data.type == 'auth') {
            this._authenticated = data.status == 'ok' ? true : false;
            return;
        }
        if (data.type == 'ping') {
            this.sendMessage({ type: 'pong' });
            return;
        }

        if (data.type == 'subscription') {
            if (this._handlers[data.uid]) {
                for (const component in this._handlers[data.uid]) {
                    
                    if (this._handlers[data.uid][component][data.event]) {
                        this._handlers[data.uid][component][data.event](data);
                    }
                }
            }
        }

        if (data.type == 'items') {
            if (this._handlers[data.uid]) {
                for (const component in this._handlers[data.uid]) {
                    
                    if (this._handlers[data.uid][component][data.action]) {
                        this._handlers[data.uid][component][data.action](data);
                    }
                }
            }
        }
    }
}

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}