<template>
    <div class="modal fade show" id="confirmDialog" tabindex="-1" aria-labelledby="confirmDialogTitle" aria-modal="true" role="dialog" style="display: block; padding-left: 0px;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('signUpForMusicQuiz')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="emit('cancel')"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="signupFirstName" class="form-label">{{$t('firstName')}}</label>
                                <input v-model="firstName" type="text" class="form-control" id="signupFirstName">
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                                <label for="signupLastName" class="form-label">{{$t('lastName')}}</label>
                                <input v-model="lastName" type="text" class="form-control" id="signupLastName">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="signupPhoneInput" class="form-label">{{$t('mobile')}}</label>
                                <input v-model="mobile" type="text" class="form-control" id="signupPhoneInput">
                            </div>
                            <div class="mb-3">
                                <label for="signupEmailInput" class="form-label">{{$t('emailAddress')}}</label>
                                <input v-model="email" type="email" class="form-control" id="signupEmailInput" aria-describedby="emailHelp">
                                <div id="emailHelp" class="form-text">{{$t('wellNeverShareYourEmail')}}</div>
                            </div>
                            <div class="mb-3">
                                <label for="signupPasswordInput" class="form-label">{{$t('password')}}</label>
                                <input v-model="password" type="password" class="form-control" id="signupPasswordInput">
                            </div>
                        </div>
                        <div class="row" v-if="error.length > 0">
                            <div class="col">
                                <div class="alert alert-danger" role="alert">
                                    {{error}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="emit('cancel')">{{$t('cancel')}}</button>
                    <button type="button" class="btn btn-primary" @click="recaptcha" :disabled="okDisabled">{{$t('signUp')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script lang="ts" setup>
    import { ref, computed, watch } from 'vue';
    import { directus } from '@/services/directus';
    import { useReCaptcha } from 'vue-recaptcha-v3'
    import { triggerFlow } from '@directus/sdk';

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()


    const recaptcha = async () => {
        await recaptchaLoaded()

        const token = await executeRecaptcha('login')
        directus.api.request(triggerFlow(
            'POST',
            'eb7e22ed-7559-4587-9e71-67f73685160f',
            {
                token: token,
            }
        ))
        .then((response: any) => {
            if (response.success) {
                okPressed();
                return;
            }
            error.value = "You need to be more human to sign up.";
        })
        .catch((error: any) => {
            error.value = 'Unknown error';
        });
    }

    const emit = defineEmits(['ok', 'cancel']);

    const email = ref('');
    const password = ref('');
    const mobile = ref('');
    const firstName = ref('');
    const lastName = ref('');
    const error = ref('');
    const okPressed = () => {
        directus.signup(email.value, password.value, mobile.value, firstName.value, lastName.value)
        .then(() => {
            window.location.reload();
        })
        .catch((error: any) => {
            if (error.message == 'Field "email" has to be unique.') {
                //error.message = $t('emailAlreadyExists');
            }
            else if (error.message == 'Invalid payload. "refresh_token" is required in either the JSON payload or Cookie.') {
                window.location.reload();
            }
            error.value = error.message;
            console.log(error.value);
        });
    }

    const okDisabled = computed(() => {
        if (email.value.length == 0 || password.value.length == 0 || mobile.value.length == 0 || firstName.value.length == 0 || lastName.value.length == 0)
        {
            return true;
        }
        if (!validEmail.value) {
            return true;
        }
        if (!validMobile.value) {
            return true;
        }

        return false;
    });

    const validMobile = computed(() => {
        if (email.value.length == 0) {
            return true;
        }

        const re = /^[0-9]{8}$/;
        return re.test(mobile.value);
    });

    const validEmail = computed(() => {
        if (email.value.length == 0) {
            return true;
        }

        const re = /^[\w-_\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
        return re.test(email.value);
    });

    const validName = computed(() => {
        if (firstName.value.length == 0 || lastName.value.length == 0) {
            return true;
        }

        const re = /^[a-zA-Z]+$/;
        return re.test(firstName.value) && re.test(lastName.value);
    });
</script>

<style scoped> 
    .artists:not(:last-child):after {
        content: ', '
    }

    .selectedRow {
        background-color: #e9ecef;
    }

    .pointer {
        cursor: pointer;
    }
</style>