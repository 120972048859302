import { defineStore } from 'pinia'
import { directus } from '../services/directus';
import { createItem, readItems, updateItem, triggerFlow } from '@directus/sdk';
import { ref } from 'vue';
import { Game } from '@/types/Game.type';

export const useGameStore = defineStore('game', () => {
    const collection = 'game';
    const component = 'game';
    let uid = '';

    const joined = ref(false);
    const game = ref<Game>();
    
    async function getGame(code: string|null): Promise<void> {
        const currentCode = localStorage.getItem('currentGame');
        const query = {
            fields: ['*', 'user_created.*', 'players.*.*', 'status'],
            filter: {
                code: {
                    _eq: code ?? currentCode ?? ''
                }
            }
        }


        const itemUpdatedHandler = (event: any) => {
                game.value = event.data[0];
                setJoined();
        }

        uid = directus.websocket.createUID(collection, query);

        const subscriptionOptions = {
            uid: uid,
            query: query,
        }

        directus.websocket.addHandler(uid, component, 'update', itemUpdatedHandler);
        directus.websocket.subscribe(collection, component, subscriptionOptions);

        return new Promise((resolve, reject) => {
            if (!code && ! currentCode) {
                return;
            }   
            directus.api.request(readItems(collection, query))
            .then((response: any) => {
                if (response.length === 0) {
                    reject('Game not found');
                    return;
                }
                game.value = response[0];
                console.log('Game found');
                setJoined();
                resolve();
            })
            .catch((error) => {
                reject(error);
                return;
            });
        });
    }

    function setJoined() {
        const me: string = game.value?.players?.filter((player) => {
            return player.directus_users_id.id == directus.id;
        })[0]?.id ?? '';

        joined.value = me !== '';
    }

    async function createGame(): Promise<void> {
        const code = await _createNewCode();
        const newGame: Game = {
            code,
            status: 'draft',
        }

        directus.api.request(createItem('game', newGame))
        .then((response: Game) => {
            game.value = response;
            if (game.value.code) {
                joinGame(game.value.code);
            }
        })
        .then(() => {
            
        });
    }

    async function _createNewCode(): Promise<string> {
        return new Promise(async (resolve) => {
            const code = _generateCode();
            gameExists(code)
            .then((id: string) => {
                if (id ==="") {
                    resolve(code);
                    return;
                }
                resolve(_createNewCode());
            })            
        });
    }

    async function gameExists(code: string): Promise<string> {
        return new Promise((resolve) => {
            if (!code) {
                resolve("");
                return;
            }
            directus.api.request(readItems('game', {
                fields: ['*', 'players.*.*'],
                filter: {
                    code: {
                        _eq: code
                    }
                }
            }))
            .then((game: any) => {
                if (game?.length === 0) {
                    resolve("");
                    return;
                }
                resolve(game[0]);
            });
        });

    }

    async function joinGame(gameCode: string): Promise<void> {
        return new Promise((resolve, reject) => {
            gameExists(gameCode)
            .then((thisGame: any) => {
                console.log('Game does exists');
                getGame(gameCode)
                .then(() => {
                    console.log('GameID', thisGame.id);
                    if (thisGame.id === "") {
                        reject('Game not found');
                        return;
                    }

                    if (thisGame.status !== 'draft') {
                        reject('Game has already begun');
                        return;
                    }

                    const me: string = thisGame?.players?.filter((player: any) => {
                        return player.directus_users_id.id == directus.id;
                    })[0]?.id ?? '';

                    if (me !== '') {
                        console.log('Already joined');
                        localStorage.setItem('currentGame', thisGame.code);
                        resolve();
                        return;
                    }


                    console.log('Add player to game');
                    directus.api.request(updateItem('game', thisGame.id, {
                        players: {
                            create: [{
                                directus_users_id: directus.id,
                                alive: "1",
                            }]
                        },
                    }))
                    .then(() => {
                        localStorage.setItem('currentGame', thisGame.code);
                        resolve();
                    });
                })
                .catch((error) => {
                    console.log("Could not find game", error);
                    reject(error);
                    return;
                });
            })
            .catch((error) => {
                console.log(error);
                reject(error);
                return;
            });
        });
    }

    async function leaveGame(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!game.value || game.value.id === "") {
                reject('Game not found');
                return;
            }

            const me: string = game.value?.players?.filter((player) => {
                return player.directus_users_id.id == directus.id;
            })[0]?.id ?? '';

            directus.websocket.unsubscribe(collection, component);
            directus.websocket.removeHandlers(uid, component);

            joined.value = false;
            if (me === '') {
                localStorage.removeItem('currentGame');
                game.value = undefined;
                resolve();
                return;   
            }

            directus.api.request(updateItem('game', game.value?.id ?? '', {
                players: {
                    delete: [me]
                },
            }))
            .then(() => {
                console.log('Remove game');
                localStorage.removeItem('currentGame');
                game.value = undefined;
                resolve();
            })
            .catch((error) => {
                console.log(error);
                reject(error);
                return;
            });
        });
    }

    async function startGame(): Promise<void> {
        directus.api.request(triggerFlow(
            'POST',
            'a0ccbf23-a2c6-430d-b34c-c77735e65f0c',
            {
                gameId: game.value?.id ?? '',
            }
        ))
        .then((response: any) => {
            if (game.value) {
                game.value.status = 'published';
            }
            console.log("game started");
        })
        .catch((error: any) => {
            console.log("Start game failed", error);
        });
    }

    async function imDead(): Promise<void> {
        const me: string = game.value?.players?.filter((player) => {
            return player.directus_users_id.id == directus.id;
        })[0]?.id ?? '';

        directus.api.request(triggerFlow(
            'POST',
            '6289506e-547c-4922-aab3-8ccdf28648c8',
            {
                gameDirectusUserId: me,
            }
        ))
        .then((response: any) => {
            console.log("I'm dead now");
        })
        .catch((error: any) => {
            console.log("I failed dying", error);
        });
    }

    function _generateCode(): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 6) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }


    return {
        game,
        joined,
        createGame,
        getGame,
        joinGame,
        leaveGame,
        startGame,
        imDead
    }
});